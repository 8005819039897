import { FromOtherStoresType } from 'shared/types/contentful/module/ShopTheLook';
import ContentfulImage from '~/components/furniturechoice/contentful-image';
import { CurrencyHelpers } from '~/helpers/currencyHelpers';
import { useFormat } from '~/helpers/hooks/useFormat';

interface FromOtherStoresProps {
  data: [{ fields: FromOtherStoresType }];
}

export function FromOtherStores({ data }: FromOtherStoresProps) {
  const { formatMessage } = useFormat({ name: 'furniturechoice' });
  if (!data.length) return <></>;
  if (!data[0].fields) {
    console.log('From other stores: Contentful embed depth too deep, unable to load items');
    return <></>;
  }
  return data.map((v, index) => (
    <div
      data-price={v.fields.price}
      key={index}
      className="relative flex flex-col justify-end border-b-2 border-b-grey-3 pb-2"
    >
      {!index && (
        <h4 className="absolute top-[-22px] whitespace-nowrap text-16">
          {formatMessage({ id: 'components.shop-the-look.from-other-stores' })}
        </h4>
      )}
      <figure className="grow">
        <ContentfulImage
          className="aspect-[4/3] object-cover"
          sizes="256px"
          aspectRatio={4 / 3}
          alt={v.fields.name}
          src={v.fields.image.fields.file.url}
        />
      </figure>
      <div>{v.fields.name}</div>
      <div className="font-bold">
        {v.fields.price
          ? CurrencyHelpers.formatForCurrency((parseFloat(v.fields.price.toString()) * 100) as number, '', 'GBP', 2)
          : ''}
      </div>
    </div>
  ));
}

export default FromOtherStores;
