import clsx from 'clsx';
import { ContentfulShopTheLookProps } from 'shared/types/contentful/module/ShopTheLook';
import { ContentfulVariant, FNCProduct, Product } from 'shared/types/product/Product';
import useSWR from 'swr';
import ContentfulImage from '~/components/furniturechoice/contentful-image';
import { sdk } from '~/sdk';
import { FromOtherStores } from './FromOtherStores';
import GetShopTheLookItem from './GetShopTheLookItem';
import ShowBlankShopTheLook from './ShowBlankShopTheLook';
import ArticleLinkButton from '../../LinkButton';

function useFetchProducts(skus: string) {
  // const { data, error, isLoading, isValidating } = useSWR(
  const { data } = useSWR('inspiration/' + skus, () => {
    if (!skus) return null;
    type FNCProductsObject = {
      [key: string]: FNCProduct;
    };
    return sdk
      .callAction<FNCProductsObject>({ actionName: 'contentful-article/products', query: { id: skus } })
      .then((res) => res);
  });
  //console.log(error);
  //console.log(data);
  //console.log(isLoading);
  //console.log(isValidating);

  return data;
}

/**
 * Loads list of referenced Furniture and Choice products in the Shop the Look listing
 */
function FetchProducts({ products }: { products: [{ fields: ContentfulVariant }] }) {
  const result = useFetchProducts(products && products.length ? products.map((v) => v.fields.sku).join('-') : '');

  if (!products || !products.length) {
    console.log('Shop the look: No products added');
    return <></>;
  }

  if (!products[0].fields) {
    console.log('Shop the look: Contentful embed depth too deep, unable to load items');
    return <></>;
  }

  if (result) {
    if (result.isError) return <></>;
    else {
      type Variants = {
        [key: string]: { contentfulVariant: ContentfulVariant; product: Product };
      };
      const variants: Variants = {};
      for (let i = 0; i < products.length; i++) {
        const sku = products[i].fields.sku;
        const p = result.data[sku];
        variants[sku] = {
          contentfulVariant: products[i].fields,
          product: p,
        };
      }

      //return result.data.map((product, index) => {
      return Object.keys(result.data).map((sku, index) => {
        return (
          <GetShopTheLookItem
            key={index}
            contentfulVariant={variants[sku].contentfulVariant}
            product={variants[sku].product}
          />
        );
      });
    }
  }

  return products.map((_v, index) => <ShowBlankShopTheLook key={index} />);
}

/**
 * Please refer to Module: Shop the look Content Model in Contentful
 **/
export default function ContentfulShopTheLook({
  name,
  heroImage,
  products,
  fromOtherStores,
  link,
  linkText,
}: ContentfulShopTheLookProps) {
  const classNames = clsx('grid grid-cols-2 gap-8 text-12 leading-5', { 'md:gap-x-4 md:grid-cols-4': true });

  return (
    <div data-stl={name}>
      {heroImage && (
        <ContentfulImage
          className="mb-4"
          sizes="800px"
          aspectRatio={heroImage.fields.file.details.image.width / heroImage.fields.file.details.image.height}
          alt={heroImage.fields.title}
          title={heroImage.fields.description}
          src={heroImage.fields.file.url}
        />
      )}
      <h4 className="mb-2 text-salmon-4">
        {(products && products.length && 'Furniture & Choice') || <span>&nbsp;</span>}
      </h4>
      <div className={classNames}>
        <FetchProducts products={products} />
        <FromOtherStores data={fromOtherStores} />
      </div>
      {link && <ArticleLinkButton link={link} text={linkText} />}
    </div>
  );
}
