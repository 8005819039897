import { Options } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import { MdCheck } from 'react-icons/md';
import SnippetItem from 'shared/types/contentful/module/Snippet';
import RichTextContentful from '~/components/furniturechoice/rich-text/contentful';
import { getDefaultOptions } from '../../page/inspiration-article';

function generateOptions(attr: SnippetItem) {
  const listType = attr.variation == 'highlight' ? 'check' : 'dash';
  const defaultOptions = getDefaultOptions();
  const modifiedOptions: Options = {
    renderNode: {
      // does not use <p> because <div> is not allowed to be a child element of <p>
      [BLOCKS.PARAGRAPH]: (_node, children) => <div className="leading-5">{children}</div>,
      [BLOCKS.UL_LIST]: (_node, children) => {
        if (listType == 'check') {
          return <ul className="pl-4">{children}</ul>;
        } else {
          return <ul className="pl-4 [&_li]:list-['—'] [&_li]:marker:text-salmon-4">{children}</ul>;
        }
      },
      [BLOCKS.LIST_ITEM]: (_node, children) => {
        if (listType == 'check') {
          return (
            <li className="mb-2 whitespace-pre-line">
              <span className="float-left -ml-6 h-5">
                <MdCheck className="fill-green-1 text-22" />
              </span>
              {children}
            </li>
          );
        } else {
          return (
            <li className="mb-2 whitespace-pre-line ps-1.5 [counter-increment:section] [&_ol]:pl-8">{children}</li>
          );
        }
      },
    },
  };

  const textOptions = {
    renderMark: { ...defaultOptions.renderMark },
    renderNode: { ...defaultOptions.renderNode, ...modifiedOptions.renderNode },
  };

  return textOptions;
}

interface SnippetProps {
  data: SnippetItem;
}

/**
 * Please refer to Module: Snippet in Content Model of Contentful for more details.
 * Generally used for embedded text or generic articles
 */
export default function Snippet({ data }: SnippetProps) {
  const textOptions = generateOptions(data);
  const className = data.variation == 'highlight' ? 'bg-grey-1 p-4 rounded' : '';
  return <RichTextContentful data={data.text} options={textOptions} className={className} />;
}
