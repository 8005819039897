import { HTMLAttributes } from 'react';
import Link from 'next/link';
import { documentToReactComponents, Options } from '@contentful/rich-text-react-renderer';
import { Document, BLOCKS, INLINES, MARKS, Block, Text } from '@contentful/rich-text-types';
import clsx from 'clsx';
import { MdFormatQuote } from 'react-icons/md';
import ContentfulImage from '~/components/furniturechoice/contentful-image';
import ContentfulModuleType from '~/types/contentful/ModuleType';
import ContentfulPageType from '~/types/contentful/PageType';
import ArticleLinkButton from './LinkButton';
import ImageLink from './module/image-link';
import ContentfulShopTheLook from './module/shop-the-look';
import Snippet from './module/snippet';
import InspirationArticle from './page/inspiration-article';

interface RichTextContentfulProps extends HTMLAttributes<HTMLDivElement> {
  data: Document;
  options?: Options;
}

export default function RichTextContentful({
  className,
  data,
  options,
  ...props
}: RichTextContentfulProps): JSX.Element {
  const defaultOptions: Options = {
    renderMark: {
      // font-bold instead of the default font-bolder
      [MARKS.BOLD]: (text) => <b className="font-bold">{text}</b>,
      ...options?.renderMark,
    },
    renderNode: {
      // We allow some unsafe javascript for arbitrary actions
      [INLINES.HYPERLINK]: (node, children) => (
        <Link className="text-salmon-4 hover:underline" href={node.data.uri}>
          {children}
        </Link>
      ),
      // standard paragraph
      [BLOCKS.PARAGRAPH]: (_node, children) => <p className="leading-5">{children}</p>,
      // two types of unordered lists, with pink dashes or green checkmarks, denoted by listType attribute
      [BLOCKS.UL_LIST]: (_node, children) => (
        <ul className="pl-4 [&_li]:list-['—'] [&_li]:marker:text-salmon-4">{children}</ul>
      ),
      // ordered lists are numeric eg. 1.2.1 for layered lists
      [BLOCKS.OL_LIST]: (_node, children) => (
        <ol className="relative mt-2 grid gap-y-2 pl-4 [counter-reset:section] [&_li]:marker:[content:counters(section,'.')'.']">
          {children}
        </ol>
      ),
      [BLOCKS.LIST_ITEM]: (_node, children) => (
        <li className="mb-2 whitespace-pre-line ps-1.5 [counter-increment:section] [&_ol]:pl-8">{children}</li>
      ),
      // we don't allow h1, so h2 is the highest
      [BLOCKS.HEADING_2]: (_node, children) => (
        <h2 className="mt-4 text-20 font-bold [&+hr]:mt-[-8px] [&+hr]:border-grey-6">{children}</h2>
      ),
      [BLOCKS.HEADING_3]: (_node, children) => <h3 className="mt-4 text-18 font-bold text-grey-5">{children}</h3>,
      [BLOCKS.HEADING_4]: (_node, children) => <h4 className="mt-4 text-16 font-bold text-grey-5">{children}</h4>,
      [BLOCKS.HR]: () => <hr className="border-grey-3" />,
      // block quotes are wrapped with md icon quotes
      // and the paragraph immediately following the block quote is centered
      [BLOCKS.QUOTE]: (_node, children) => (
        <blockquote className="text-center text-salmon-4 after:m-auto after:my-2 after:block after:h-1 after:w-12 after:bg-salmon-1 after:content-[''] [&+div]:text-center [&_div]:inline">
          <MdFormatQuote className="inline rotate-180 align-baseline text-22" />
          {children}
          <MdFormatQuote className="inline align-baseline text-22" />
        </blockquote>
      ),
      // wrapped with <tbody> to stop react from complaining
      [BLOCKS.TABLE]: (_node, children) => (
        <table className="border-collapse border-2 border-grey-8">
          <tbody>{children}</tbody>
        </table>
      ),
      [BLOCKS.TABLE_HEADER_CELL]: (_node, children) => (
        <th className="bg-grey-2 p-2 text-left font-bold text-grey-5">{children}</th>
      ),
      [BLOCKS.TABLE_CELL]: (_node, children) => <td className="p-2">{children}</td>,
      // special: styled link used in article pages
      [BLOCKS.HEADING_5]: (_node, children) => {
        return (
          <figure className="flex justify-center gap-x-4 align-middle">
            <ContentfulImage
              className="block w-[104px] justify-end"
              src="https://images.ctfassets.net/x715brg11yrw/3Ap15v8BlsMHVxhJfyL0wF/491928ab591e226e6ed536004b7a1ae1/logo-1-.png"
              alt="Furniture and Choice"
              no-pin="no-pin"
              sizes="104px"
            />
            <figcaption className="justify-start whitespace-pre-line text-14">{children}</figcaption>
          </figure>
        );
      },
      // special: pink link button
      [BLOCKS.HEADING_6]: (node) => {
        let link = '';
        let text = '';
        node.content.forEach((n) => {
          if (n.nodeType === INLINES.HYPERLINK) link = n.data.uri;

          if ((n as Block).content && (n as Block).content[0].nodeType == 'text') {
            text = ((n as Block).content[0] as Text).value;
          }
        });

        return <ArticleLinkButton link={link} text={text} />;
      },
      // this handles linking to other contentful articles
      [INLINES.ENTRY_HYPERLINK]: (node, children) => {
        // link must be valid
        if (node.data.target.sys.contentType && node.data.target.sys.contentType.sys.id == ContentfulPageType.ARTICLE) {
          return (
            <Link className="text-salmon-4 hover:underline" href={'/inspiration/' + node.data.target.fields.slug}>
              {children}
            </Link>
          );
          // TODO: replace with either variant slug or code to fetch slug from merchant centre
        } else if (node.data.target.sys.contentType && node.data.target.sys.contentType.sys.id == 'variant') {
          return (
            <Link
              className="text-salmon-4 hover:underline"
              href={'/p/product_' + node.data.target.fields.sku.toLowerCase()}
            >
              {children}
            </Link>
          );
        } else {
          return children;
        }
      },
      // embedded images, title is used as caption, description is used as the hyperlink
      [BLOCKS.EMBEDDED_ASSET]: (node) => (
        <ContentfulImage className="w-full" src={node.data.target.fields.file.url as string} sizes="100%" />
      ),
      // embed other items within this article
      // update this section when new Content Types are added
      [BLOCKS.EMBEDDED_ENTRY]: (node) => {
        if (!node.data.target.fields) return <></>; // embed too deep
        switch (node.data.target.sys.contentType.sys.id) {
          case ContentfulPageType.ARTICLE:
            return <InspirationArticle data={node.data.target.fields} />;
          case ContentfulModuleType.SHOP_THE_LOOK:
            return <ContentfulShopTheLook {...node.data.target.fields} />;
          case ContentfulModuleType.SNIPPET:
            return <Snippet data={node.data.target.fields} />;
          case ContentfulModuleType.IMAGE_LINK:
            return <ImageLink data={node.data.target.fields} />;
          default:
            return <></>;
        }
      },
      // items which are allowed to be embedded inline
      // update this section when new Content Types are added if needed
      [INLINES.EMBEDDED_ENTRY]: (node) => {
        if (!node.data.target.fields) return <></>; // embed too deep
        switch (node.data.target.sys.contentType.sys.id) {
          case ContentfulModuleType.SNIPPET:
            return <Snippet data={node.data.target.fields} />;
          case ContentfulModuleType.IMAGE_LINK:
            return <ImageLink data={node.data.target.fields} />;
          default:
            return <></>;
        }
      },
      ...options?.renderNode,
    },
    preserveWhitespace: options?.preserveWhitespace,
    renderText: options?.renderText,
  };

  return (
    <div className={clsx('flex flex-col gap-4 text-grey-5 lg:gap-6', className)} {...props}>
      {documentToReactComponents(data, defaultOptions)}
    </div>
  );
}
