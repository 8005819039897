import { ContentfulVariant, Product } from 'shared/types/product/Product';
import ContentfulImage from '~/components/furniturechoice/contentful-image';
import Link from '~/components/furniturechoice/link';
import { CurrencyHelpers } from '~/helpers/currencyHelpers';

interface GetShopTheLookItemProps {
  contentfulVariant: ContentfulVariant;
  product: Product;
}

export default function GetShopTheLookItem({ contentfulVariant, product }: GetShopTheLookItemProps) {
  if (!contentfulVariant || !product) return <></>;

  const media = contentfulVariant.medias;

  const productUrl = product.categories ? (product.categories[0]._url || '') + (product._url || '') : '';
  const selectedVariant = product.variants.find((variant) => variant.sku === contentfulVariant.sku);
  const productPrice =
    selectedVariant && selectedVariant.price
      ? CurrencyHelpers.formatForCurrency(
          selectedVariant.price.centAmount as number,
          '',
          selectedVariant.price.currencyCode,
          selectedVariant.price.fractionDigits,
        )
      : '';

  const item = (
    <div
      className="relative flex flex-col justify-end border-b-2 border-b-grey-3 pb-2"
      data-href={productUrl}
      data-id={product.slug}
    >
      <Link href={productUrl}>
        <figure className="grow">
          <ContentfulImage
            className="aspect-[4/3] object-cover"
            src={media[0].fields.file.url}
            sizes="300px"
            aspectRatio={media[0].fields.file.details.image.width / media[0].fields.file.details.image.height}
          />
        </figure>
      </Link>
      <div>
        <Link className="text-salmon-4" href={productUrl}>
          {product.name}
        </Link>
      </div>
      <div className="font-bold">{productPrice}</div>
    </div>
  );

  return item;
}
