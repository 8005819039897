import { ContentfulImage as ContentfulImageType } from 'shared/types/contentful/image';
import ContentfulImage from '~/components/furniturechoice/contentful-image';
import Link from '~/components/furniturechoice/link';

interface ImageLinkItem {
  internalName: string;
  image: ContentfulImageType;
  newLink: string;
}

interface ImageLinkProps {
  data: ImageLinkItem;
}

// TODO: incomplete
export default function ImageLink({ data }: ImageLinkProps) {
  const content = (
    <figure className="relative">
      <ContentfulImage className="w-full" src={data.image.fields.file.url as string} sizes="100%" />
      {data.internalName && (
        <>
          <div className="absolute bottom-0 left-0 h-20 w-full bg-gradient-to-b from-black/0 to-black/50"></div>
          <figcaption className="absolute bottom-0 left-0 z-25 w-full px-8 py-4 text-14 text-white">
            {data.internalName}
          </figcaption>
        </>
      )}
    </figure>
  );

  if (data.newLink) {
    return <Link href={data.newLink}>{content}</Link>;
  } else {
    return content;
  }
}
