export default function ShowBlankShopTheLook() {
  return (
    <div className="w-full border-b-2 border-b-grey-3 pb-2">
      <div className="grid animate-pulse gap-y-2 p-4">
        <div className="h-[120px] w-full rounded bg-grey-2"></div>
        <div className="py-1">
          <div className="space-y-3">
            <div className="grid grid-cols-2 gap-2">
              <div className="col-span-2 h-2 rounded bg-grey-2"></div>
              <div className="col-span-1 h-2 rounded bg-grey-2"></div>
            </div>
            <div className="h-2 w-24 rounded bg-grey-2"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
