import { HTMLAttributes } from 'react';
import { Options } from '@contentful/rich-text-react-renderer';
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types';
import clsx from 'clsx';
import ContentfulInspiration from 'shared/types/contentful/page/Inspiration';
import Link from '~/components/furniturechoice/link';
import RichTextContentful from '~/components/furniturechoice/rich-text/contentful';

interface InspirationArticleProps extends HTMLAttributes<HTMLDivElement> {
  data: ContentfulInspiration;
  options?: Options;
}

function processCustomCode(text: string) {
  const [t, fn] = text.trim().split(':');

  switch (fn) {
    case 'custom_function':
      const clickHandler = () => {
        window.alert('Custom function has been clicked');
      };
      return (
        <span className="cursor-pointer text-salmon-4" onClick={clickHandler}>
          {t}
        </span>
      );
  }

  return t;
}

export function getDefaultOptions() {
  const defaultTextOptions: Options = {
    renderMark: {
      // special: used for any special custom functionality needed
      [MARKS.CODE]: (text) => processCustomCode(text as string),
    },
    renderNode: {
      // does not use <p> because <div> is not allowed to be a child element of <p>
      [BLOCKS.PARAGRAPH]: (_node, children) => <div className="leading-5">{children}</div>,
      [INLINES.HYPERLINK]: (node, children) => {
        if (node.data.uri.trim().match(/^javascript\:/)) {
          const href = `<a class="text-salmon-4 hover:underline" href="${node.data.uri.replace('"', "'")}">
            ${children}
          </a>`;
          return <span dangerouslySetInnerHTML={{ __html: href }}></span>;
        } else {
          return (
            <Link className="text-salmon-4 hover:underline" href={node.data.uri}>
              {children}
            </Link>
          );
        }
      },
    },
  };

  return defaultTextOptions;
}

function generateOptions(options?: Options) {
  const defaultOptions = getDefaultOptions();

  if (options) {
    return {
      renderMark: { ...defaultOptions.renderMark, ...options.renderMark },
      renderNode: { ...defaultOptions.renderNode, ...options.renderNode },
      preserveWhitespace: options?.preserveWhitespace,
      renderText: options?.renderText,
    };
  } else {
    return defaultOptions;
  }
}

/**
 * Please refer to Article in Content Model of Contentful for more information.
 * Used to display rich text inspiration articles.
 */
export default function InspirationArticle({ data, className, options, ...props }: InspirationArticleProps) {
  const textOptions = generateOptions(options);
  const wrapperClassName = clsx('m-auto max-w-screen-md', {
    className,
  });

  return <RichTextContentful data={data.text} options={textOptions} className={wrapperClassName} {...props} />;
}

// TODO: make real loader
export function ShowBlankArticle() {
  return (
    <div className="mx-auto w-full max-w-sm p-4">
      <div className="flex animate-pulse space-x-4">
        <div className="h-10 w-10 rounded-full bg-grey-2"></div>
        <div className="flex-1 space-y-6 py-1">
          <div className="h-2 rounded bg-grey-2"></div>
          <div className="space-y-3">
            <div className="grid grid-cols-3 gap-4">
              <div className="col-span-2 h-2 rounded bg-grey-2"></div>
              <div className="col-span-1 h-2 rounded bg-grey-2"></div>
            </div>
            <div className="h-2 rounded bg-grey-2"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
